/* eslint-disable react/no-unused-class-component-methods */

'use client';

import React from 'react';
import { Link } from 'react-router-dom';
import iconCaretWhite from '@frontend/jetlend-assets/icons/icon--caret--white.svg';
import iconCaretGreen from '@frontend/jetlend-assets/icons/icon--caret--green.svg';
import iconCaretGray from '@frontend/jetlend-assets/icons/icon--caret--gray.svg';
import styles from './button.module.scss';
import {
    buildClassNames,
    mergeClassNames,
} from '../../../utils/classNameUtils';
import { stopPropagation } from '../../../utils/stopPropagation';

import Tooltip from '../../TooltipIcon/Tooltip';

import Loader, {
    LoaderSize,
    IProps as LoaderProps,
} from '../../loaders/Loader';
import { getAssetSrc } from '../../../utils/getAssetSrc';

export type ButtonSize = 'big'|'normal'|'small'|'thin'|'xsmall';
export type ButtonType = 'default'|'primary'|'warning'|'danger'|'secondary'|'plain'|'alternative'|'success'|'gradient_main'|'gradient_secondary'|'gradient_accent' | 'gradient_pin'| 'gradient_pin_delete'| 'gradient_pin_face_id'|'grey_dark'|'white'|'light'|'ultra';
export type AlignType = 'left'|'center'|'right';
export type ButtonActionType = 'button'|'submit'|'reset';

export interface ButtonProps extends React.PropsWithChildren {
    className?: string;
    textClassName?: string;
    containerClassName?: string;
    iconClassName?: string;
    text?: string;
    type?: ButtonType;
    size?: ButtonSize;
    outline?: boolean;
    noSpacing?: boolean;
    caret?: boolean;
    disabled?: boolean;
    align?: AlignType;
    block?: boolean;
    width?: string|number;
    href?: string;
    external?: boolean;
    icon?: string;
    /**
     * Позиция иконки (справа | слева)
     */
    iconPosition?: 'left' | 'right';
    title?: string;
    loading?: boolean;
    buttonType?: ButtonActionType;
    testId?: string;
    onClick?: (e: React.MouseEvent) => void;
    rightContent?: string|number;
    rightContentWidth?: number;
}

interface IState {
    focused: boolean;
}

class Button extends React.Component<ButtonProps, IState> {
    private buttonRef: React.RefObject<any>;

    constructor(props: ButtonProps) {
        super(props);

        this.buttonRef = React.createRef<any>();

        this.state = {
            focused: false,
        };
    }

    focus() {
        if (this.buttonRef) {
            const buttonElement = this.buttonRef.current;

            if (buttonElement instanceof HTMLButtonElement && typeof buttonElement.focus === 'function') {
                buttonElement.focus();
            }
        }
    }

    getButtonType() {
        return this.props.type || 'default';
    }

    getButtonSize() {
        return this.props.size || 'normal';
    }

    getButtonClassName() {
        return mergeClassNames([
            buildClassNames(styles, [
                'button',
                `button--${this.getButtonType()}`,
                `button--${this.getButtonSize()}`,
                `button--align--${this.props.rightContent ? 'left' : this.props.align}`,
                this.props.caret ? 'button--caret' : null,
                this.props.disabled ? 'button--disabled' : null,
                this.props.outline ? 'button--outline' : null,
                this.props.block ? 'button--block' : null,
                this.props.loading ? 'button--loading' : null,
            ]),
            this.props.className,
        ]);
    }

    getButtonContainerClassName() {
        const { focused } = this.state;

        return mergeClassNames([
            buildClassNames(styles, [
                'button-container',
                this.props.size ? `button-container--${this.props.size}` : null,
                focused ? 'button-container--focus' : null,
                (this.props.noSpacing || this.props.block) ? 'button-container--no-spacing' : null,
                this.props.block ? 'button-container--block' : null,
            ]),
            this.props.containerClassName,
        ]);
    }

    handleButtonFocus() {
        this.setState({
            focused: true,
        });
    }

    handleButtonBlur() {
        this.setState({
            focused: false,
        });
    }

    didButtonClick(e: React.MouseEvent) {
        if (this.props.onClick) {
            stopPropagation(e);
        }

        // Don't trigger any click handlers when disabled or loading
        if (this.props.loading || this.props.disabled) {
            e.preventDefault();
            return;
        }

        this.props.onClick && this.props.onClick(e);
    }

    private getLoaderSize(buttonSize: ButtonSize): LoaderSize {
        switch (buttonSize) {
        case 'xsmall':
        case 'small':
        case 'thin':
            return 'small';
        case 'normal':
            return 'normal';
        case 'big':
            return 'large';
        }
    }

    private getLoaderProps(): LoaderProps {
        return {
            size: this.getLoaderSize(this.getButtonSize()),
            inverted: !this.props.outline &&
                ['default', 'primary', 'danger'].includes(this.getButtonType()),
        };
    }

    private getCaretIcon(): string {
        switch (this.getButtonType()) {
        case 'primary':
        case 'danger':
        case 'default':
            return iconCaretWhite;
        case 'white':
            return iconCaretGray;
        default:
            return iconCaretGreen;
        }
    }

    render() {
        const buttonTextClassName = mergeClassNames([
            styles['button__text'],
            this.props.textClassName,
        ]);

        const buttonIconClassName = mergeClassNames([
            styles['button__icon'],
            this.props.iconClassName,
        ]);

        const iconPosition = this.props.iconPosition ?? 'left';
        const buttonInnerElement = this.props.text
            ? (
                <span className={buttonTextClassName}>
                    {this.props.text}
                </span>
            )
            : (
                <span className={buttonTextClassName}>
                    {this.props.children}
                </span>
            );

        const buttonIcon = this.props.loading
            ? (
                <span className={styles['button__loading-indicator']}>
                    <Loader {...this.getLoaderProps()} />
                </span>
            )
            : (this.props.icon && <img className={buttonIconClassName} src={getAssetSrc(this.props.icon)} alt="" />);

        const buttonContent = (
            <>
                {iconPosition === 'left' && buttonIcon}
                {this.props.caret
                    ? (
                        <div className={styles['button__content--caret']}>
                            {buttonInnerElement}
                            <div className={styles['icon--caret']}>
                                <img src={this.getCaretIcon()} alt="" />
                            </div>
                        </div>
                    )
                    : buttonInnerElement
                }
                {iconPosition === 'right' && buttonIcon}
                {this.props.rightContent && (
                    <div
                        className={styles['button__right-content']}
                        style={this.props.rightContentWidth && { width: this.props.rightContentWidth }}
                    >
                        {this.props.rightContent}
                    </div>
                )}
            </>
        );

        let innerControl;
        if (this.props.href) {
            if (this.props.external) {
                innerControl = (
                    <a
                        ref={r => this.buttonRef}
                        href={this.props.href}
                        target="_blank"
                        rel="noopener nofollow noreferrer"
                        className={this.getButtonClassName()}
                        type={this.props.buttonType || 'button'}
                        onFocus={e => this.handleButtonFocus()}
                        onBlur={e => this.handleButtonBlur()}
                        onClick={e => this.didButtonClick(e)}
                        style={{ minWidth: this.props.width || (this.props.block ? '100%' : '') }}
                        data-test-id={this.props.testId}
                    >
                        {buttonContent}
                    </a>
                );
            } else {
                innerControl = (
                    <Link
                        ref={this.buttonRef}
                        to={this.props.href}
                        reloadDocument={this.props.href.includes('#')}
                        className={this.getButtonClassName()}
                        type={this.props.buttonType || 'button'}
                        onFocus={e => this.handleButtonFocus()}
                        onBlur={e => this.handleButtonBlur()}
                        onClick={e => this.didButtonClick(e)}
                        style={{ minWidth: this.props.width || (this.props.block ? '100%' : '') }}
                        data-test-id={this.props.testId}
                    >
                        {buttonContent}
                    </Link>
                );
            }
        } else {
            innerControl = (
                <button
                    ref={r => this.buttonRef}
                    className={this.getButtonClassName()}
                    type={this.props.buttonType || 'button'}
                    disabled={this.props.disabled}
                    onFocus={e => this.handleButtonFocus()}
                    onBlur={e => this.handleButtonBlur()}
                    onClick={e => this.didButtonClick(e)}
                    style={{ minWidth: this.props.width || (this.props.block ? '100%' : '') }}
                    data-test-id={this.props.testId}
                >
                    {buttonContent}
                </button>
            );
        }

        if (this.props.title) {
            innerControl = (
                <Tooltip disableWrapper title={this.props.title}>
                    {innerControl}
                </Tooltip>
            );
        }

        return (
            <span className={this.getButtonContainerClassName()} style={{ width: this.props.block ? '100%' : '' }}>
                {innerControl}
            </span>
        );
    }
}

export default Button;